/* 初期招待メッセージ用 */
.ogp_container_href {
  height: 100px;
  margin-top: -10%;
  margin-bottom: -7%;
}

.ogp_maincontainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ogp_image_container_width {
  width: 50%;
}

.ogp_image_width {
  height: 100px;
  width: 100px;
}

.ogp_organizer {
  color: #646464;
}

.chat_message_html img {
  width: 100%;
}

.chat_indicator {
  text-align: center;
}

.display-none {
  display: none;
}
.error_container {
  display: flex;
  align-items: center;
  color: #e61919
}